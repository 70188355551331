.fastfilter-box {
  width: 100%;
  z-index: 999;
  background: #ffffff;
  position: fixed;
  padding: 0 0 10px 0;
  z-index: 4;
  left: 0px;
  right: 0px;
  bottom: 0em;

  &.thank {
    bottom: 82em;
  }

  &.open {
    bottom: 60em;
  }
  &.close {
    bottom: 0em!important;
  }
  .close-filter-fast {
    position: absolute;
    right: 5px;
    top: -35px;
    padding: 8px;

    img {
      filter: invert(1);
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    padding: 20px 20px 0;
    background: #ffeee6;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 20px;
    .delivery-icon{
      margin: 0;
      line-height: 0px;
    }
    .delivery-heading {
      font-size: 20px;
      line-height: 22px;
      font-family: 'pangrammedium';
      color: #e27a34;
      letter-spacing: 0.01em;
      text-align: left;

      .delivery-subhead {
        font-size: 14px;
        line-height: 18px;
        display: block;
        text-align: left;
        font-family: 'pangramregular';
        color: #646464;
        padding-top: 3px;
      }
    }
  }

  .field__form {
    padding: 10px 15px 20px;

    .thankyou {
      font-family: 'pangramregular';
    }

    .form {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    .formgroup {
      display: flex;
      position: relative;
      margin-bottom: 15px;
      flex-direction: column;
      flex: 1 1 calc(50% - 15px);

      label {
        font-family: 'pangramregular';
        color: #4b4a4a;
        font-size: 13px;
        opacity: 0;
        line-height: 1;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        padding: 0 5px;
        text-overflow: ellipsis;
        top: -5px;
        left: 10px;
        display: inline-block;
        text-align: left;
        transform: translateY(3px);
        transition: all 0.2s ease-out;
        user-select: none;
        white-space: nowrap;
        background: #fff;
        z-index: 1;

        &.field__label {
          opacity: 1;
          transform: none;
        }
      }

      input {
        width: 100%;
        height: 50px;
        font-size: 14px;
        padding: 0 15px;
        color: #4b4a4a;
        border-radius: 4px;
        border-radius: 2px;
        background-color: #fff;
        border: 1px solid #e5e5e5;
        border: 1px solid #d1d1d1;
        font-family: 'pangramregular';
        transition: all 0.2s ease-out;

        &.texterror {
          border-color: #e32526;
        }

        &:focus {
          border-color: #e65c00;
        }
      }

      .btngroup button {
        width: -webkit-fill-available;
        color: #e32526
      }

      p {
        text-align: left;
        font-size: 12px;
        font-family: 'pangramregular';
        text-transform: capitalize;
        padding: 3px 0px 0px;
        color: red !important;
        display: block;
      }
    }
  }

  .btngroup {
    .btn-primary {
      display: block;
      text-align: center;
    }
  }
}